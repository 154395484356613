import { useMemo, useState } from 'react'
import {
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  Collapse
} from 'reactstrap'

import logo from './../../logo-vtv-support-top.png'
import logofinitalia from "./../../finitalia.png";
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuth, selectMain } from '../../reducers'
import { toggleUserInfoPopupAct, updateSelectedComponentValueAct } from '../Layout/_actions'
import { SelectOption } from '../../types/state'
import { useNavigate } from 'react-router-dom';
import { getSelectedComponent, goToWithSelectedComponent, isFinitaliaFunction } from '../../utils/utils';
import { store as suppModuleStore } from 'vitruvius-support-module'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate();
  const main = useSelector(selectMain);
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch()

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const goToHome = (selectedComponentValue: string) => {
    goToWithSelectedComponent(navigate, window.REACT_APP_BASE_PATH + "/admin/communications", selectedComponentValue)
  }

  const goToDashboard = (selectedComponentValue: string) => {
    goToWithSelectedComponent(navigate, window.REACT_APP_BASE_PATH + "/admin/dashboard", selectedComponentValue)

  }

  const handleSelectedComponent = (selectedComponentOption: any) => {
    dispatch(updateSelectedComponentValueAct(selectedComponentOption.value))
    suppModuleStore && suppModuleStore.dispatch(updateSelectedComponentValueAct())
    goToHome(selectedComponentOption.value)
  }

  const handleLogoClick = () => {
    if (
      main?.selectedComponentValue === 'LINEAR'
    ) {
      goToDashboard(main?.selectedComponentValue)
    } else {
      goToHome(main?.selectedComponentValue)
    }
  }

  const userInfoToggle = () => {
    dispatch(toggleUserInfoPopupAct());
  };

  const selectedComponentItem: SelectOption | undefined = useMemo(() => {
    return getSelectedComponent(main.availableComponents, main.selectedComponentValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [main.selectedComponentValue, main.availableComponents?.length])

  const _isFinitalia = isFinitaliaFunction(main?.selectedComponentValue)

  return (
    <Navbar
      color='dark'
      expand='lg'
      className={
        !_isFinitalia ? 'navbar-absolute fixed-top navbar-dark' : 'navbar-absolute fixed-top navbar-dark finitalia-header'
      }
    >
      <div className='navbar-wrapper'>
        <div className='logo' onClick={handleLogoClick}>
          <div className='simple-text logo-mini'>
            <div className='logo-img'>
              <img
                src={_isFinitalia ? logofinitalia : logo}
                alt='react-logo'
              />
            </div>
          </div>
        </div>
      </div>
      <NavbarToggler onClick={toggle}>
        <span className='navbar-toggler-bar navbar-kebab' />
        <span className='navbar-toggler-bar navbar-kebab' />
        <span className='navbar-toggler-bar navbar-kebab' />
      </NavbarToggler>
      <Collapse isOpen={isOpen} navbar className='justify-content-end'>
        <Nav navbar>
          <NavItem>
            <Select
              className='react-select-custom'
              value={selectedComponentItem}
              onChange={handleSelectedComponent}
              options={
                main.availableComponents
              }
            />
          </NavItem>
          <Dropdown nav toggle={userInfoToggle}>
            <DropdownToggle
              caret
              nav
              className={auth.showPopupUserInfo ? 'activated' : ''}
            >
              <span className='header-icon-label'>
                {auth?.userInfo?.username}
              </span>
              <i className='nc-icon nc-badge' />
            </DropdownToggle>
          </Dropdown>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default Header
