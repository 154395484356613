import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import { sendPost } from '../../utils/http.service';
import { PasswordActionType } from '../../types/actions/password-actions';
import { setPasswordErrorAct } from './actions';
import { HandleChangeExpiredPasswordType, HandleChangeForgotPasswordType, HandleChangePasswordLoggedInType, HandleValidateResetTokenType, SendForgotPasswordActType } from '../../types/actions/action-types';


export function* handleSendForgotPassword(action: any) {
    const { userId, navigate }: SendForgotPasswordActType = action.payload;
    const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/auth/password/forgot`;
    const _body = { userId };

    try {
        yield call(sendPost, requestURL, _body);
        yield navigate(window.REACT_APP_BASE_PATH + '/auth/pwd-recovery-success')
    } catch (err) {
        yield navigate(window.REACT_APP_BASE_PATH + '/auth/error');
    }
}

export function* handleChangePasswordLoggedIn(action: any) {
    const { newPassword, navigate }: HandleChangePasswordLoggedInType = action.payload;
    const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/auth/password/change`;
    const _body = { newPassword };

    try {
        yield call(sendPost, requestURL, _body);
        yield navigate(window.REACT_APP_BASE_PATH + '/auth/change-pwd-success')
    } catch (err: any) {
        if (err?.response?.data?.error === "New password cannot be the same as the old password") {
            yield put(setPasswordErrorAct('La nuova password deve essere diversa da quella precendente'));
        } else {
            yield navigate(window.REACT_APP_BASE_PATH + '/auth/error');
        }
    }
}

export function* handleChangeExpiredPassword(action: any) {
    const { userId, oldPassword, newPassword, navigate }: HandleChangeExpiredPasswordType = action.payload;
    const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/auth/password/change-expired`;
    const _body = { userId, oldPassword, newPassword };

    try {
        yield call(sendPost, requestURL, _body);
        yield navigate(window.REACT_APP_BASE_PATH + '/auth/change-pwd-success')
    } catch (err: any) {
        yield navigate(window.REACT_APP_BASE_PATH + '/auth/error');
    }
}

export function* handleChangeForgotPassword(action: any) {
    const { token, newPassword, navigate }: HandleChangeForgotPasswordType = action.payload;
    const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/auth/password/reset`;
    const _body = { token, newPassword };

    try {
        yield call(sendPost, requestURL, _body);
        yield navigate(window.REACT_APP_BASE_PATH + '/auth/change-pwd-success')
    } catch (err: any) {
        yield navigate(window.REACT_APP_BASE_PATH + '/auth/error');
    }
}

export function* handleValidateResetToken(action: any) {
    const { token, navigate }: HandleValidateResetTokenType = action.payload;
    const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/auth/password/validate-token`;
    const _body = { token };

    try {
        yield call(sendPost, requestURL, _body);
    } catch (err: any) {
        if (err?.response?.status === 419) yield navigate(window.REACT_APP_BASE_PATH + '/auth/pwd-recovery-expired')
        else yield navigate(window.REACT_APP_BASE_PATH + '/auth/error')
    }
}

// Watchers
export function* watchSendForgotPassword() {
    yield takeEvery(PasswordActionType.SEND_FORGOT_PASSWORD, handleSendForgotPassword);
}

export function* watchChangePasswordLoggedIn() {
    yield takeEvery(PasswordActionType.CHANGE_PASSWORD_LOGGED_IN, handleChangePasswordLoggedIn);
}

export function* watchChangeExpiredPassword() {
    yield takeEvery(PasswordActionType.CHANGE_EXPIRED_PASSWORD, handleChangeExpiredPassword);
}

export function* watchChangeForgotPassword() {
    yield takeEvery(PasswordActionType.CHANGE_FORGOT_PASSWORD, handleChangeForgotPassword);
}

export function* watchValidateResetToken() {
    yield takeEvery(PasswordActionType.VALIDATE_RESET_TOKEN, handleValidateResetToken);
}

// End of saga
function* passwordSaga() {
    yield all([
        fork(watchSendForgotPassword),
        fork(watchChangePasswordLoggedIn),
        fork(watchChangeExpiredPassword),
        fork(watchChangeForgotPassword),
        fork(watchValidateResetToken),
    ]);
}

export default passwordSaga;