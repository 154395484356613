import { PasswordActionType } from "../../types/actions/password-actions";

export interface PasswordState {
    passwordError: string,
}

export const initialState: PasswordState = {
    passwordError: '',
};

export const passwordReducer = (state = initialState, action: any) => {

    switch (action.type) {
        case PasswordActionType.SET_CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                passwordError: action.payload.error,
            }
        default:
            return state;
    }
};
