import React from "react";
import { VtvSupport } from "vitruvius-support-module";
import "vitruvius-support-module/src/scss/core.scss";

import "vitruvius-support-module/src/assets/scss/paper-dashboard.scss";
import "vitruvius-support-module/src/assets/demo/demo.css";
import { useSelector } from "react-redux";
import { selectMain } from "../../reducers";
import { goToWithSelectedComponent, LOCAL_STORAGE_KEY, SupportQuerystringParamEnum } from "../../utils/utils";
import { CommunicationType } from "../../types/communications";
import { useNavigate } from "react-router-dom";

const CommunicationsPage = () => {
  const navigate = useNavigate();
  const main = useSelector(selectMain);

  const goToDetails = (_selectedCommunication: CommunicationType) => {
    const idQs = `${SupportQuerystringParamEnum.ID}=${_selectedCommunication?._id}`
    const componentQs = `${SupportQuerystringParamEnum.COMPONENT}=${main?.selectedComponentValue}`
    navigate({
      pathname: window.REACT_APP_BASE_PATH + "/admin/communications/details",
      search: `?${idQs}&${componentQs}`,
    });
  };

  const goToDashboard = () => {
    goToWithSelectedComponent(navigate, window.REACT_APP_BASE_PATH + "/admin/dashboard", main?.selectedComponentValue)

  };

  return (
    <React.Fragment>
      <VtvSupport
        localStorageKey={LOCAL_STORAGE_KEY}
        selectedComponentValue={main.selectedComponentValue}
        showCommunications={true}
        showDetails={false}
        communications_goToDetails={goToDetails}
        goBack={goToDashboard}
        goToDashboard={goToDashboard}
        takeAllSentAndFailed={true}
      />
    </React.Fragment>
  );
};

export default CommunicationsPage
