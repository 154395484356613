import axios from 'axios';

export const sendPost = (_requestURL: string, _body: any) => {
    return axios.post(_requestURL, _body);
}

export const sendPut = (_requestURL: string, _body: any) => {
    return axios.put(_requestURL, _body);
}

export const sendPatch = (_requestURL: string, _body: any) => {
    return axios.patch(_requestURL, _body);
}

export const sendGet = (_requestURL: string, config?: any) => {
    return axios.get(_requestURL, config);
}

export const sendDelete = (_requestURL: string) => {
    return axios.delete(_requestURL);
}
