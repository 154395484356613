import { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { submitLoginAct } from './../AuthLayout/_actions';
import Loader from './../../components/Loader/Loader';
import logo from "./../../logo-vtv-support-login-azzurro.png";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "./../../reducers";
import { useNavigate } from 'react-router-dom';
import { getValidTokenPresent, LOCAL_STORAGE_KEY, readFromQueryString, SupportQuerystringParamEnum } from '../../utils/utils';
import isFeatureEnabled from "../../utils/feature-flags.service";
import { Eye, EyeOff } from "../../components/icons/UntitledUI";


const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(selectAuth);
    const [inputData, setInputData] = useState({
        username: '',
        pswd: '',
    });
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        // Clear local storage and cookies data
        localStorage.removeItem(LOCAL_STORAGE_KEY)
        Cookies.remove('x-session-cookie');
        Cookies.remove('x-session-cookie', { secure: true });
    }, [])

    useEffect(() => {
        if (getValidTokenPresent()) {
            const nowId = readFromQueryString(SupportQuerystringParamEnum.NOW_ID)
            if (nowId) {
                navigate(window.REACT_APP_BASE_PATH + '/admin/communications?nowId=' + nowId);
            } else {
                navigate(window.REACT_APP_BASE_PATH + '/admin/communications');
            }
        };
    }, [navigate])

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setInputData(currVal => ({ ...currVal, [name]: value }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const { username, pswd } = inputData;
        dispatch(submitLoginAct({ username, pswd, navigate }))
    }

    const goToPasswordRecovery = () => {
        navigate('/auth/pwd-recovery')
    }

    const isInputValid = inputData.username && inputData.pswd;
    const errorOccurred = auth?.loginError;

    return (
        <div className="wrapper wrapper-full-page ps section-image">
            <div style={{ padding: "0 16px" }} className="login-page">
                <div className="container">
                    <div className="row">
                        <div className="ml-auto mr-auto">
                            <form onSubmit={handleSubmit} className="form">
                                <div style={{ maxWidth: "400px" }} className="card-login card">

                                    <div className={"loader-container " + (auth?.isLoginLoading ? 'loading-visible' : '')}>
                                        <div><Loader />
                                        </div>
                                    </div>

                                    <div className="card-header">
                                        <div className="card-header">

                                            <div className="header-container">
                                                <img style={{ width: "100%", maxWidth: "300px" }} src={logo} alt="Remote Support logo" />
                                            </div>

                                            <p className="text-description">Effettua il <strong>Login</strong> per seguire le comunicazioni, trovare tutte le informazioni utili e portare avanti il loro stato direttamente dal portale.</p>
                                        </div>

                                        <div className="card-body">

                                            <div className="input-group">

                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" style={{ padding: "0.375rem 0.75rem" }}> <i className="nc-icon nc-single-02" /> </span>
                                                </div>

                                                <input placeholder="Nome Utente..."
                                                    type="text"
                                                    name="username"
                                                    value={inputData.username}
                                                    onChange={handleChange}
                                                    className="form-control" />
                                            </div>

                                            <div className="input-group">

                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" style={{ padding: "0.375rem 0.75rem" }}>
                                                        <i className="nc-icon nc-key-25" >
                                                        </i>
                                                    </span>
                                                </div>

                                                <input placeholder="Password"
                                                    autoComplete="off"
                                                    type={showPassword ? "text" : "password"}
                                                    name="pswd"
                                                    value={inputData.pswd}
                                                    onChange={handleChange}
                                                    className="form-control" />

                                                <div className="input-group-append">
                                                    <span
                                                        className="input-group-text" style={{ padding: "0.375rem" }}
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        data-testid="show-hide-pwd">
                                                        {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                                                    </span>
                                                </div>
                                            </div>

                                            {isFeatureEnabled(window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE) &&
                                                <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={goToPasswordRecovery}>
                                                    Hai dimenticato la password?
                                                </span>}

                                        </div>

                                    </div>

                                    <div className={"error-msg-info " + (errorOccurred ? 'element-shown' : '')}>
                                        <p>Si è verificato un errore in fase di login. Controlla la correttezza dei dati forniti e ripeti l'operazione.</p>
                                    </div>

                                    <div className="card-footer">
                                        <input type="submit"
                                            className={"btn-round mb-3 btn btn-info btn-block " + (!isInputValid ? 'disabled' : '')}
                                            value="Procedi" />
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default LoginPage;