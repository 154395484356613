import { useEffect, useState } from "react";
import { AlertCircle, Eye, EyeOff } from "../../components/icons/UntitledUI";
import { useDispatch, useSelector } from "react-redux";
import { pwdMinRequirements } from "../../utils/password-service";
import { useLocation, useNavigate } from "react-router-dom";
import { changeExpiredPasswordAct, changeForgotPasswordAct, changePasswordLoggedInAct, validateResetTokenAct } from "../../store/password/actions";
import './change-password.scss';

interface Props {
    isExpired?: boolean;
    isReset?: boolean
}

const ChangePassword = (props: Props) => {
    const { isExpired, isReset } = props;

    const query = new URLSearchParams(useLocation().search);
    const resetToken = query.get('token') || '';
    const [username, setUsername] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pwdErrorFromServer = useSelector((state: any) => state.password.passwordError);
    

    let oldPasswordError = '';
    let newPasswordError = '';
    if (password !== '' && confirmPassword !== '' && password !== confirmPassword) newPasswordError = 'Le password non corrispondono';
    else if (password !== '' && confirmPassword !== '' && !pwdMinRequirements(password)) newPasswordError = 'La password non rispetta i requisiti minimi';
    else if (isExpired && password !== '' && oldPassword === password) newPasswordError = 'La password deve essere diversa da quelle precedenti'

    const submitDisabled = (newPasswordError !== '' || password === '') || (isExpired && username === '');

    const handleSubmit = () => {
        if (isExpired) dispatch(changeExpiredPasswordAct({
            userId: username, oldPassword, newPassword: password, navigate
    }));
        else if (isReset) dispatch(changeForgotPasswordAct({
            token: resetToken, newPassword: password, navigate
    }));
        else dispatch(changePasswordLoggedInAct(
            {newPassword: password, navigate}));
    }

    useEffect(() => {
        isReset && dispatch(validateResetTokenAct({token: resetToken, navigate}));
    }, [dispatch, resetToken, isReset, navigate]);

    return (
        <div className="wrapper wrapper-full-page ps section-image">
            <div className="change-pwd-container">
                <h3 style={{ textAlign: "left" }} className="title">
                    {isReset ? 'Ho dimenticato la password' : 'Cambio password'}
                </h3>
                {isExpired && <p style={{color: "red"}}><b>La tua password è scaduta</b></p>}
                {pwdErrorFromServer !== '' && <p style={{ color: "red" }}><b>{pwdErrorFromServer}</b></p>}
                    <div className="change-pwd-form-container">

                        <div className="left-side">

                            {isExpired &&
                                <>
                                    <label>
                                        Username
                                        <div className="input-container">
                                            <input
                                                name="password"
                                                type="text"
                                                value={username}
                                                onChange={(evt) => setUsername(evt.target.value)}
                                                autoComplete="username"
                                                className="form-control" />
                                        </div>
                                    </label>

                                    <label>
                                        Vecchia password
                                        <div className="input-container">
                                            <input
                                                name="password"
                                                type={showOldPassword ? "text" : "password"}
                                                value={oldPassword}
                                                onChange={(evt) => setOldPassword(evt.target.value)}
                                                autoComplete="new-password"
                                                className={`form-control ${oldPasswordError !== '' ? 'error' : ''}`} />
                                            <span
                                                className={`show-hide-password ${oldPasswordError !== '' ? 'error' : ''}`}
                                                onClick={() => oldPasswordError === '' && setShowOldPassword(!showOldPassword)}>
                                                {oldPasswordError !== '' ?
                                                    <AlertCircle size={18} />
                                                    :
                                                    showOldPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                                            </span>
                                        </div>
                                        {oldPasswordError !== '' && <p style={{ color: "red" }}>{oldPasswordError || ' '}</p>}
                                    </label>
                                </>
                            }

                            <label>
                                Nuova password
                                <div className="input-container">
                                    <input
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={(evt) => setPassword(evt.target.value)}
                                        autoComplete="new-password"
                                        className={`form-control ${newPasswordError !== '' ? 'error' : ''}`} />
                                    <span
                                        className={`show-hide-password ${newPasswordError !== '' ? 'error' : ''}`}
                                        onClick={() => newPasswordError === '' && setShowPassword(!showPassword)}>
                                        {
                                            newPasswordError !== '' ?
                                                <AlertCircle size={18} />
                                                :
                                                showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                                    </span>
                                </div>
                                {newPasswordError !== '' && <p style={{ color: "red" }}>{newPasswordError || ' '}</p>}
                            </label>

                            <label>
                                Conferma nuova password
                                <div className="input-container">
                                    <input
                                        name="confirm-password"
                                        type={showConfirmPassword ? "text" : "password"}
                                        value={confirmPassword}
                                        onChange={(evt) => setConfirmPassword(evt.target.value)}
                                        autoComplete="new-password"
                                        className={`form-control ${newPasswordError !== '' ? 'error' : ''}`} />
                                    <span
                                        className={`show-hide-password ${newPasswordError !== '' ? 'error' : ''}`}
                                        onClick={() => newPasswordError === '' && setShowConfirmPassword(!showConfirmPassword)}>
                                        {newPasswordError !== '' ?
                                            <AlertCircle size={18} />
                                            :
                                            showConfirmPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                                    </span>
                                </div>
                                {newPasswordError !== '' && <p style={{ color: "red" }}>{newPasswordError || ' '}</p>}
                            </label>
                        </div>

                        <div className="right-side">
                            <p>Requisiti minimi:</p>
                            <ul>
                                <li>La password deve avere <strong>almeno 8 caratteri;</strong> </li>
                                <li>Deve includere una <strong>lettera maiuscola</strong>, un <strong>numero</strong> e un <strong>carattere speciale</strong> (es. !, @, #, $);</li>
                                <li> Deve essere <strong>differente da quelle precedenti.</strong></li>
                            </ul>
                        </div>

                    </div>
                    <div className="footer">
                        <button
                            onClick={handleSubmit}
                            className="btn-round mb-3 btn btn-info"
                            disabled={submitDisabled}>
                            Conferma
                        </button>
                    </div>
            </div>
        </div>
    );
}

export default ChangePassword;
