import { combineReducers } from 'redux'
import { layoutReducer } from './containers/Layout/_reducers'
import { authReducer } from './containers/AuthLayout/_reducers'
import { passwordReducer } from './store/password/reducer'

const createRootReducer = () => {
  const appReducer = combineReducers({
    main: layoutReducer,
    auth: authReducer,
    password: passwordReducer,
  })

  const rootReducer = (state: any, action: any) => {
    return appReducer(state, action)
  }

  return rootReducer;
}

export const selectMain = (state: any) => {
  return state[`main`]
};
export const selectAuth = (state: any) => {
  return state[`auth`]
};


export default createRootReducer;