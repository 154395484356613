import { useEffect, useRef } from "react";
import PerfectScrollbar from "perfect-scrollbar";

import { NavigateFunction, Outlet, useNavigate } from "react-router-dom";
import { loadUserInfoAct, loadAppConfigAct, loadGroupsAct, updateSelectedComponentValueAct } from './_actions';

import UserInfo from "../../components/UserInfo/UserInfo";

import "vitruvius-support-module/src/scss/core.scss";
import "vitruvius-support-module/src/assets/scss/paper-dashboard.scss";
import "vitruvius-support-module/src/assets/demo/demo.css";
import { useDispatch, useSelector } from "react-redux";
import { selectMain } from "../../reducers";
import { readFromQueryString, SupportQuerystringParamEnum } from "../../utils/utils";
import applyAxiosInterceptor from "../../utils/http-interceptor-config";

import "./Dashboard.scss"
import Header from "../Header/Header";

var ps: any;

const Dashboard = () => {
  const dispatch = useDispatch();
  const mainPanel: any = useRef()
  const navigate: NavigateFunction = useNavigate();

  const main = useSelector(selectMain);
  const groupsLoaded = main?.availableComponents?.length

  useEffect(() => {
    // apply interceptor
    applyAxiosInterceptor(navigate);

    if (!navigator.platform || navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel?.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    dispatch(loadAppConfigAct());
    dispatch(loadUserInfoAct());

    const component: any = readFromQueryString(SupportQuerystringParamEnum.COMPONENT)
    if (component) {
      dispatch(updateSelectedComponentValueAct(component))
    }

    if (!groupsLoaded) {
      dispatch(loadGroupsAct())
    }

    return () => {
      if (!navigator.platform || navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="wrapper">
        <div className="main-panel" id="main-panel" ref={mainPanel}>
          <Header />
          <Outlet />
        </div>
        <UserInfo />
      </div>
    </>
  );
}

export default Dashboard
