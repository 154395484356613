import {
    createStore,
    applyMiddleware,
    compose
} from 'redux';
import {
    createBrowserHistory
} from 'history'
import createRootReducer from './../reducers';
import createSagaMiddleware from 'redux-saga';
import layoutSaga from '../containers/Layout/saga';
import loginSaga from './../containers/login/saga';

import {
    initialState as authInitialState
} from './../containers/AuthLayout/_reducers';
import {
    initialState as mainInitialState
} from '../containers/Layout/_reducers';
import { LOCAL_STORAGE_KEY } from './utils';
import passwordSaga from '../store/password/saga';

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const actionsToTriggerSaveLocalStorage = [
    'SUBMIT_LOGIN_SUCCESS',
    'F5_SUBMIT_LOGIN_SUCCESS',
    'F5_REFRESH_TOKEN_SUCCESS',
    'LOGOUT',
]

const getLoObj_localStorage = (_store) => {
    let stateToSave = {
        auth: _store.auth
    };
    stateToSave.auth = Object.assign({}, stateToSave.auth, {});
    stateToSave.auth.loginInput = Object.assign({}, {});
    stateToSave.auth.isLoginLoading = false;

    return stateToSave;
}

/**
 * Add all the state in local storage
 * @param getState
 * @returns {function(*): function(*=)}
 */
const localStorageMiddleware = ({
    getState
}) => {
    return (next) => (action) => {
        const result = next(action);
        if (actionsToTriggerSaveLocalStorage.indexOf(action.type) >= 0) {
            const localStorageObj = getLoObj_localStorage(getState());
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(
                localStorageObj
            ));
        }
        return result;
    };
};

const reHydrateStore = () => {
    if (localStorage.getItem(LOCAL_STORAGE_KEY) !== null) {
        let appState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) // re-hydrate the store
        appState.main = Object.assign({}, mainInitialState, appState.main);
        appState.auth = Object.assign({}, authInitialState, appState.auth);

        return appState;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
    createRootReducer(),
    reHydrateStore(),
    composeEnhancers(
        applyMiddleware(sagaMiddleware, localStorageMiddleware)
    )
)

sagaMiddleware.run(layoutSaga)
sagaMiddleware.run(loginSaga);
sagaMiddleware.run(passwordSaga);