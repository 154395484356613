import axios from 'axios';
import { closeDefaultModalAct } from '../containers/Layout/_actions';
import { refreshCookiesAct, stsLogoutAct, LogoutAct } from '../containers/AuthLayout/_actions';
import createStore from './storeConfig';
import { getTokenFromStorage, LOCAL_STORAGE_KEY, readFromQueryString, SupportQuerystringParamEnum } from './utils';
import { NavigateFunction } from 'react-router';

const stsUrls = [
  window.REACT_APP_VTV_LOGIN_API,
  window.REACT_APP_VTV_LOGOUT_API,
  window.REACT_APP_VTV_REFRESH_API
]

const store = createStore;
const { dispatch } = store;

export const requestHandler = (request: any) => {
  if (localStorage.getItem(LOCAL_STORAGE_KEY) !== null) {
    const authInfoToken = getTokenFromStorage()
    if (authInfoToken) {
      request.headers['Authorization'] = `Bearer ${authInfoToken}`;
    }
  }

  const foundStsUrl = stsUrls.find(_url => (request.url && request.url.endsWith(_url)))
  if (!foundStsUrl) {
    dispatch(refreshCookiesAct());
  }

  return request;
}

export const logout = (navigate: NavigateFunction) => {
  if (!window.location || window.location.pathname !== (window.REACT_APP_BASE_PATH + '/auth/login')) {
    dispatch(closeDefaultModalAct())
    dispatch(LogoutAct())
    let routeStr = window.REACT_APP_BASE_PATH + '/auth/login';
    const nowId = readFromQueryString(SupportQuerystringParamEnum.NOW_ID)
    if (nowId) {
      routeStr += `?nowId=${nowId}`
    }
    navigate(routeStr)
  }
};

export const manageError = (error: any, navigate: NavigateFunction) => {
  if (error && error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 302)) {
    const foundStsUrl = stsUrls.find(_url => (error.response.request.responseURL && error.response.request.responseURL.endsWith(_url)))
    if (!foundStsUrl) dispatch(stsLogoutAct());
    logout(navigate);
  }
  return Promise.reject(error);
}

/* istanbul ignore next */
const applyAxiosInterceptor = (navigate: NavigateFunction) => {
  axios.interceptors.request.use(
    request => requestHandler(request)
  )
  axios.interceptors.response.use(response => {
    return response;
  }, error => { // Any status codes that falls outside the range of 2xx cause this function to trigger
    return manageError(error, navigate);
  });
};

export default applyAxiosInterceptor;