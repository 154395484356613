import { jwtDecode } from "jwt-decode";
import { SelectOption } from "../types/state"
import queryString from 'query-string';
import { NavigateFunction } from "react-router-dom";

export const LOCAL_STORAGE_KEY = 'applicationState:support'

export enum SupportQuerystringParamEnum {
    ID = 'id',
    TOKEN = 'token',
    COMPONENT = 'component',
    NOW_ID = 'nowId',
}

export const getSelectedComponent = (
    _avComponents: SelectOption[], _selectedComponentValue: string
): SelectOption | undefined => {

    if (!_avComponents?.length) {
        return
    }

    const foundGroup = _avComponents.find(avCmp => avCmp.value === _selectedComponentValue)
    return foundGroup || _avComponents[0]
}


export const computeSelectedComponentValue = (
    _avComponents: SelectOption[], _selectedComponentValue: string
) => {
    const foundCmp = getSelectedComponent(_avComponents, _selectedComponentValue)
    return foundCmp?.value
}

export const getTokenFromStorage = (): string | null => {
    let appState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (appState) {
        let LOappStateJson = JSON.parse(appState);
        if (LOappStateJson.auth && LOappStateJson.auth.authInfo) {
            return LOappStateJson.auth.authInfo.token;
        }
    }
    return null;
}
export const getValidTokenPresent = () => {
    try {

        const token = getTokenFromStorage();
        if (!token) {
            return;
        }

        let decodedToken = jwtDecode(token);

        let expDate = decodedToken && decodedToken.exp;

        if (!expDate) {
            return;
        }

        const dateNow = Math.floor(Date.now() / 1000);

        if (dateNow > expDate) {
            return;
        }

        return token;
    } catch (err) {
        console.error('invalid token found in local storage');
    }
}

export const readFromQueryString = (qsName: SupportQuerystringParamEnum): any => {
    const search = queryString.parse(window?.location?.search);
    return search && search[qsName];
}

export const isFinitaliaFunction = (selectedComponentValue?: string): boolean => {
    return selectedComponentValue === "FINITALIA"
}

export const goToWithSelectedComponent = (navigate: NavigateFunction, urlTo: string, selectedComponentValue: string) => {
    navigate(`${urlTo}?${SupportQuerystringParamEnum.COMPONENT}=${selectedComponentValue}`);
}