export const submitLoginAct = (loginInfo) => ({
  type: 'SUBMIT_LOGIN',
  payload: loginInfo
})

export const f5SubmitLoginSuccessAct = f5LoginResponseInfo => ({
  type: 'F5_SUBMIT_LOGIN_SUCCESS',
  payload: f5LoginResponseInfo
})

export const f5SubmitRefreshSuccessAct = f5RefreshResponseInfo => ({
  type: 'F5_REFRESH_TOKEN_SUCCESS',
  payload: f5RefreshResponseInfo
})

export const submitLoginSuccessAct = loginResponseInfo => ({
  type: 'SUBMIT_LOGIN_SUCCESS',
  payload: loginResponseInfo
})

export const submitLoginErrorAct = errorInfo => ({
  type: 'SUBMIT_LOGIN_ERROR',
  payload: errorInfo
})

export const LogoutAct = () => ({ type: 'LOGOUT' })
export const stsLogoutAct = () => ({ type: 'STS_LOGOUT' })
export const refreshCookiesAct = () => ({ type: 'REFRESH_COOKIES' })
export const resetStorageAct = () =>({ type: 'RESET_STORAGE' })

export const mfaLoginAct = (otp) => ({ type: 'SUBMIT_MFA', payload: otp, });
export const mfaLoginErrorAct = (errorMessage) => ({ type: 'SUBMIT_MFA_ERROR', payload: errorMessage });
export const mfaLoginSuccessAct = (successMessage) => ({ type: 'SUBMIT_MFA_SUCCESS', payload: successMessage });
export const mfaResendOtpAct = () => ({ type: 'SUBMIT_RESEND_OTP' });
export const mfaResendOtpErrorAct = (errorMessage) => ({ type: 'SUBMIT_RESEND_OTP_ERROR', payload: errorMessage });
export const mfaResendOtpSuccessAct = (successMessage) => ({ type: 'SUBMIT_RESEND_OTP_SUCCESS', payload: successMessage });
