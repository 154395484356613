import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { loadUserInfoSuccessAct, loadUserInfoErrorAct, loadAppConfigSuccessAct, loadAppConfigErrorAct, loadGroupsSuccessAct, loadGroupsErrorAct } from './_actions';

export const getStateCommunications = (state) => state.communications

function getUserDataHttp(requestURL) {
    return axios.get(requestURL);
}

function getPostDataHttp(_requestURL, _body) {
    return axios.post(_requestURL, _body)
}

export function* getUserDataFromServer() {
    let requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/userInfo`;
    try {
        const response = yield call(getUserDataHttp, requestURL);
        if (response.data.opResult === true) {
            yield put(loadUserInfoSuccessAct(response.data.data));
        } else {
            yield put(loadUserInfoErrorAct(response && response.data && response.data.error));
        }
    } catch (err) {
        yield put(loadUserInfoErrorAct(err));
        return;
    }
}

/* See issue VTV-306
const activateHotjar = (_hjid)=> {
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:_hjid,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}
*/

export function* getAppConfigFromServer() {
    let requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/configuration`;

    try {
        const response = yield call(getUserDataHttp, requestURL);
        if (response.data.opResult === true) {
            yield put(loadAppConfigSuccessAct(response.data.data));
            // activateHotjar(response.data.data.hjid); // See VTV-306
        } else {
            yield put(loadAppConfigErrorAct(response.data.error));
        }
    } catch (err) {
        yield put(loadAppConfigErrorAct(err));
        return;
    }
}

export function* getGroupsRequest(action) {
    try {
        const requestURL_group = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/groups`
        const response_group = yield call(
            getPostDataHttp,
            requestURL_group,
            null
        )
        if (
            response_group &&
            response_group.data &&
            response_group.data.data
        ) {
            yield put(loadGroupsSuccessAct({ groups: response_group.data.data }))
        }

    } catch (err) {
        loadGroupsErrorAct(err)
    }
}

export default function* getUserData() {
    yield takeLatest('LOAD_USER_INFO', getUserDataFromServer);
    yield takeLatest('LOAD_APP_CONFIG', getAppConfigFromServer);
    yield takeLatest('LOAD_GROUPS', getGroupsRequest)
}
