import { useRef } from 'react';
import '../../assets/scss/commons.scss';
import './otp.scss';

const DEFAULT_OTP_SIZE = 6;

interface Props {
    otp: string[];
    setOtp: (otp: string[]) => void;
    otpError: string;
    numberOfDigits?: number;
}

const OtpInput = ({ otp, setOtp, otpError, numberOfDigits = DEFAULT_OTP_SIZE }: Props) => {
    const otpBoxReference = useRef<Array<HTMLInputElement | null>>([]);

    const handleChange = (evt: any, index: any) => {
        let newOtp = [...otp];
        newOtp[index] = (evt.target.validity.valid) ? evt.target.value : '';
        setOtp(newOtp);
    }

    const handleKeyUp = (e: any, index: number) => {

        if (/^[0-9]$/.test(e.key) && otp[index] !== '') { // update and go forward
            let newOtp = [...otp];
            newOtp[index] = e.key;
            setOtp(newOtp);
            e.target.value !== '' && index < numberOfDigits - 1 && otpBoxReference.current[index + 1]?.focus();
        } else if (e.key === "ArrowLeft") { // go back
            index > 0 && otpBoxReference.current[index - 1]?.focus();
        } else if (e.key === "ArrowRight") { // go forward
            index < numberOfDigits - 1 && otpBoxReference.current[index + 1]?.focus();
        } else if (e.key === "Backspace") { // erase cell and go back
            if (e.target.value !== '') {
                let newOtp = [...otp];
                newOtp[index] = '';
                setOtp(newOtp);
            }
            index > 0 && otpBoxReference.current[index - 1]?.focus();
        } else { // go forward
            e.target.value !== '' && index < numberOfDigits - 1 && otpBoxReference.current[index + 1]?.focus();
        }
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text');
        const digits = pasteData.replace(/[^0-9]/g, ''); // Only allow numeric characters
        if (digits.length > 0) {
            const newOtp = [...otp];
            for (let i = 0; i < digits.length && i < numberOfDigits; i++) {
                newOtp[i] = digits[i];
            }
            setOtp(newOtp);
            const lastUpdatedIndex = Math.min(digits.length - 1, numberOfDigits - 1);
            otpBoxReference.current[lastUpdatedIndex]?.focus();
        }
    };

    return (
        <>
            <div className='otp-container' data-testid="mfa-otp-container">
                {otp.map((digit, index) => (
                    <input
                        key={index}
                        value={digit}
                        type="text"
                        pattern="[0-9]"
                        className={otpError ? 'red-border' : 'light-border'}
                        maxLength={1}
                        onChange={(e) => handleChange(e, index)}
                        onKeyUp={(e) => handleKeyUp(e, index)}
                        onPaste={handlePaste}
                        ref={(reference) => (otpBoxReference.current[index] = reference)}
                    />
                ))}
            </div>
            {otpError !== '' && <p className='mt1 otp-error'>{otpError}</p>}
        </>
    );
}

export default OtpInput;