import { useNavigate } from 'react-router-dom';
import './error-page.scss';

const ErrorPage = () => {
    const navigate = useNavigate();
    
    const goToHome = () => {
        navigate('/admin')
    }

    return (
        <div className="wrapper wrapper-full-page ps section-image">
            <div className="change-pwd-container">

                <h3 style={{textAlign: "left"}} className="title">Errore</h3>
                <p>Si è verificato un errore, riprova più tardi.</p>
                <div style={{textAlign: "center"}} className="footer">
                    <button
                        className="btn-round mb-3 btn btn-info "
                        onClick={goToHome}>
                        Torna alla Home
                    </button>
                </div>

            </div>
        </div>
    );
}

export default ErrorPage;
