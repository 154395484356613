import './password-recovery.scss';
import { useNavigate } from "react-router-dom";

const PasswordRecoveryExpired = () => {
    const navigate = useNavigate();

    const goToLogin = () => {
        navigate('/auth/login')
    }

    return (
        <div className="wrapper wrapper-full-page ps section-image">
            <div className="password-recovery-container">

                <h3 style={{ textAlign: "left" }} className="title">Avviso link scaduto</h3>
                <p>Il link che stai cercando di raggiungere è scaduto. Per cambiare la password rimanda la richiesta.</p>
                <div style={{ textAlign: "center" }} className="footer">
                    <button
                        className="btn-round mb-3 btn btn-info "
                        onClick={goToLogin}>
                        Torna al login
                    </button>
                </div>

            </div>
        </div>
    );
}

export default PasswordRecoveryExpired;
