import React from "react";
import { VtvSupport } from "vitruvius-support-module";
import { useSelector } from "react-redux";
import { selectMain } from "../../reducers";
import { goToWithSelectedComponent, LOCAL_STORAGE_KEY } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

const CommunicationsDashboardPage = () => {
  const navigate = useNavigate();
  const main = useSelector(selectMain);

  const goToHome = () => {
    goToWithSelectedComponent(navigate, window.REACT_APP_BASE_PATH + "/admin/dashboard", main?.selectedComponentValue)
  } 


  const goToCommunications = () => {
    goToWithSelectedComponent(navigate, window.REACT_APP_BASE_PATH + "/admin/communications", main?.selectedComponentValue)
  } 


  return (
    <React.Fragment>
      <VtvSupport
        localStorageKey={LOCAL_STORAGE_KEY}
        selectedComponentValue={main.selectedComponentValue}
        showCommunications={false}
        showDashboard={true}
        showDetails={false}
        // communications_goToDetails={goToDetails}
        goToCommunications={goToCommunications}
        goToDashboard={goToHome}
        componentKey={Date.now()}
        takeAllSentAndFailed={true}
      />
    </React.Fragment>
  );
};

export default CommunicationsDashboardPage
