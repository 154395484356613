import { useState } from "react";
import { useDispatch } from "react-redux";
import './password-recovery.scss';
import { sendForgotPasswordAct } from "../../store/password/actions";
import { useNavigate } from "react-router-dom";

const PasswordRecovery = () => {
    const [userId, setUserId] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onProceed = () => {
        dispatch(sendForgotPasswordAct({userId, navigate}));
    }

    return (
        <div className="wrapper wrapper-full-page ps section-image">
            <div className="password-recovery-container">
                <h3 style={{ textAlign: "left" }} className="title">Ho dimenticato la password</h3>

                    <div className="password-recovery-form-container">

                        <div className="left-side">
                            <label>
                                Inserisci ID personale
                                <div className="input-container">
                                    <input
                                        name="password"
                                        type="text"
                                        value={userId}
                                        onChange={(evt) => setUserId(evt.target.value)}
                                        autoComplete="username"
                                        className="form-control" />
                                </div>
                            </label>
                        </div>

                        <div className="right-side">
                            <ul>
                                <li>Se utilizzi un ID aziendale, non puoi recuperare la password. 
                                    Potrai recuperare la password tramite piattaforma aziendale
                                </li>
                                <li>Se utilizzi un ID personale inseriscilo per recuperare la password</li>
                            </ul>
                        </div>

                    </div>
                    <div className="footer">
                        <button
                            onClick={onProceed}
                            className="btn-round mb-3 btn btn-info"
                            disabled={userId === ''}>    
                            Avanti
                        </button>
                    </div>
            </div>
        </div>
    );
}

export default PasswordRecovery;
