import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "../../components/Otp/OtpInput";
import { mfaLoginAct, mfaLoginErrorAct, mfaResendOtpAct } from "../AuthLayout/_actions";
import Loader from "../../components/Loader/Loader";
import { ApplicationState } from "../../types/state";
import { useNavigate } from 'react-router-dom';
import './mfa.scss';


const MultiFactorAuth = () => {
    const [otp, setOtp] = useState<string[]>(new Array(6).fill(''));
    const [newOtpSpinner, setNewOtpSpinner] = useState(false);
    const auth = useSelector((state: ApplicationState) => state.auth);
    const otpError = auth?.otpError || '';
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleMfaSubmit = () => {
        dispatch(mfaLoginAct({ otp: otp.join(""), navigate }));
        setTimeout(() => dispatch(mfaLoginErrorAct('')), 5000);
    }

    const isOtpComplete = (someOTP: string[]) => {
        return someOTP.every(el => el !== "");
    };

    const requestNewOtp = () => {
        setNewOtpSpinner(true);
        dispatch(mfaResendOtpAct());
        setTimeout(() => setNewOtpSpinner(false), 1500);
    }

    useEffect(() => {
        if (!auth?.authInfo?.correlationId ||
            !auth?.loginInput?.username) {
                navigate('/auth/login');
        }
    }, [dispatch, auth?.authInfo?.correlationId, auth?.loginInput?.username, navigate]);

    const onResetOtpError = () => {
        setOtp(new Array(6).fill(''));
        dispatch(mfaLoginErrorAct(''));
    }

    return (
        <div className="mfa-page-container wrapper wrapper-full-page ps section-image">
            <div className="login-page">
                <div className="container">
                    <div className="flex">
                        <div className="card ml-auto mr-auto">

                            <div className={"loader-container " + (auth.isLoginLoading ? 'loading-visible' : '')}>
                                <Loader />
                            </div>

                            <div className="card-header">
                                <h3 className="title">Autenticazione a doppio fattore</h3>
                                <div className="card-header">
                                    <p>
                                        Se sei un utente Vitruvius riceverai una mail con il codice di verifica.
                                        Inseriscilo di seguito per effettuare il login.
                                    </p>

                                    <br />
                                    <p>Non hai ricevuto il codice o è scaduto?
                                        <span
                                            style={{ cursor: "pointer", color: "#51cbce", marginLeft: "5px" }}
                                            onClick={requestNewOtp}>
                                            Richiedine un altro
                                            {newOtpSpinner && <span style={{ width: "20px", height: "20px", marginLeft: "5px" }} className="spinner-border" role="status"></span>}
                                        </span>
                                    </p>

                                </div>
                            </div>

                            <div style={{ marginBottom: "4em", marginTop: "4em" }} className="card-body">
                                <div style={{marginBottom: "1em"}}>
                                    <OtpInput
                                        otp={otp}
                                        setOtp={setOtp}
                                        otpError={otpError} />
                                </div>
                                {isOtpComplete(otp) &&
                                    <span
                                        style={{ cursor: "pointer", textDecoration: "underline" }}
                                        onClick={onResetOtpError}>
                                        Reset
                                    </span>}
                            </div>

                            <div className="footer">
                                <button
                                    style={{ width: "70%" }}
                                    onClick={handleMfaSubmit}
                                    className={"btn-round mb-3 btn btn-info " + ((isOtpComplete(otp) && otpError !== '') ? 'disabled' : '')}
                                    disabled={(!isOtpComplete(otp) || otpError !== '')}>
                                    Avanti
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MultiFactorAuth;