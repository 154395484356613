import axios from 'axios';
import {
    call,
    put,
    select,
    takeLatest,
    takeEvery
} from 'redux-saga/effects';
import {
    submitLoginSuccessAct,
    submitLoginErrorAct,
    f5SubmitLoginSuccessAct,
    f5SubmitRefreshSuccessAct,
    mfaLoginErrorAct,
    mfaResendOtpErrorAct,
    mfaResendOtpSuccessAct,
} from './../AuthLayout/_actions';
import Cookies from 'js-cookie';
import { goToWithSelectedComponent, readFromQueryString, SupportQuerystringParamEnum } from '../../utils/utils';
import { sendPost, sendGet } from '../../utils/http.service';
import isFeatureEnabled from '../../utils/feature-flags.service';
import { EXPIRED_PASSWORD_CODE } from '../../utils/constants';

function callHttpFormExecution(_requestURL, _body) {
    try {
        const params = new URLSearchParams();
        params.append('username', _body.username);
        params.append('password', _body.pswd);
        return axios.post(_requestURL, params, {
            maxRedirects: 0
        });
    } catch (err) {
        return err;
    }
}


export function* executeLoginFromServer(action) {
    if (`${window.REACT_APP_VTV_LOGIN_MODE}` === "STS") {
        let requestURL = `${window.REACT_APP_VTV_LOGIN_API}`;
        let stateAuth = yield select(state => state.auth);
        let _body = stateAuth.loginInput;
        try {
            yield call(callHttpFormExecution, requestURL, _body);
            let cookieVal = Cookies.get('x-session-cookie')
            yield put(f5SubmitLoginSuccessAct(cookieVal));
            yield executeLogin(action);
        } catch (err) {
            yield executeLogin(action);
        }
    } else {
        yield executeLogin(action);
    }
}

export function* executeLogin(action) {
    const { navigate } = action.payload
    let requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/auth/userInfo`;
    let stateAuth = yield select(state => state.auth);
    let _body = {
        username: stateAuth.loginInput && stateAuth.loginInput.username,
        password: stateAuth.loginInput && stateAuth.loginInput.pswd
    };

    try {
        const response = yield call(sendPost, requestURL, _body);
        const responseData = response.data;
        yield put(submitLoginSuccessAct(responseData));
        if (responseData.correlationId != null
            && isFeatureEnabled(window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE)) {
            yield navigate(window.REACT_APP_BASE_PATH + '/auth/mfa');
        } else {
            const nowId = readFromQueryString(SupportQuerystringParamEnum.NOW_ID)
            if (nowId) {
                yield navigate(window.REACT_APP_BASE_PATH + '/admin/communications?nowId=' + nowId);
            } else {
                const groupRequestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/groups`;
                const groupResponse = yield call(sendPost, groupRequestURL, null)
                if (groupResponse && groupResponse.data && groupResponse.data.data && groupResponse.data.data.length) {
                    const groupName = groupResponse.data.data[0].name;
                    if (groupName === 'LINEAR') {
                        return yield goToWithSelectedComponent(navigate, window.REACT_APP_BASE_PATH + "/admin/dashboard", groupName)
                    }
                }
                yield navigate(window.REACT_APP_BASE_PATH + '/admin/communications');
            }
        }


    } catch (err) {
        if (err?.response?.status === EXPIRED_PASSWORD_CODE) {
            yield put(submitLoginSuccessAct(null));
            yield navigate(window.REACT_APP_BASE_PATH + '/auth/change-expired-pwd');
        } else yield put(submitLoginErrorAct(err));
    }
}

export function* executeSTSLogout() {
    if (`${window.REACT_APP_VTV_LOGIN_MODE}` === "STS") {
        yield call(sendGet, `${window.REACT_APP_VTV_LOGOUT_API}`);
        yield put(f5SubmitRefreshSuccessAct(''));
        Cookies.remove('x-session-cookie');
        Cookies.remove('x-session-cookie', { secure: true });
    }
}

export function* executeRefreshCookies() {
    if (`${window.REACT_APP_VTV_LOGIN_MODE}` === "STS") {
        yield call(sendGet, `${window.REACT_APP_VTV_REFRESH_API}`);
        let cookieVal = Cookies.get('x-session-cookie')
        yield put(f5SubmitRefreshSuccessAct(cookieVal));
    }
}


export function* executeMfa(action) {
    const { otp, navigate } = action.payload;
    const stateAuth = yield select(state => state.auth);
    const _body = {
        userId: stateAuth.loginInput?.username,
        correlationId: stateAuth?.authInfo?.correlationId,
        otp: otp,
    };
    const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/auth/mfa/validate-otp`;

    try {
        const response = yield call(sendPost, requestURL, _body);
        if (!response) throw new Error("Si è verificato un errore durante la validazione dell'OTP");

        yield put(submitLoginSuccessAct(response?.data?.data?.user));
        const nowId = readFromQueryString(SupportQuerystringParamEnum.NOW_ID)
        if (nowId) {
            yield navigate(window.REACT_APP_BASE_PATH + '/admin/communications?nowId=' + nowId);
        } else {
            const groupRequestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/groups`;
            const groupResponse = yield call(sendPost, groupRequestURL, null)
            if (groupResponse && groupResponse.data && groupResponse.data.data && groupResponse.data.data.length) {
                const groupName = groupResponse.data.data[0].name;
                if (groupName === 'LINEAR') {
                    return yield goToWithSelectedComponent(navigate, window.REACT_APP_BASE_PATH + "/admin/dashboard", groupName)
                }
            }
            yield navigate(window.REACT_APP_BASE_PATH + '/admin/communications')
        }
    } catch (err) {
        if (err?.response?.status === 400) yield put(mfaLoginErrorAct("Codice errato"));
        else if (err?.response?.status === 419) yield put(mfaLoginErrorAct("Codice scaduto"));
        else yield put(mfaLoginErrorAct("Si è verificato un errore durante la validazione dell'OTP"));
    }
}

export function* executeResendOtp(action) {
    let requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/auth/mfa/request-otp`;
    const stateAuth = yield select(state => state.auth);
    const _body = {
        userId: stateAuth.loginInput?.username,
        correlationId: stateAuth?.authInfo?.correlationId,
    };

    try {
        const response = yield call(sendPost, requestURL, _body);
        if (!response) throw new Error("Si è verificato un errore durante la richiesta del nuovo OTP");
        yield put(mfaResendOtpSuccessAct('mfa resend success'));
    } catch (err) {
        if (err?.response?.status === 429) yield put(mfaResendOtpErrorAct("Sono state effettuate troppe richieste, riprovare più tardi."));
        else yield put(mfaResendOtpErrorAct("Si è verificato un errore durante la richiesta del nuovo OTP"));
    }
}

export default function* loginSaga() {
    yield takeLatest('SUBMIT_LOGIN', executeLoginFromServer);
    yield takeEvery('STS_LOGOUT', executeSTSLogout);
    yield takeEvery('REFRESH_COOKIES', executeRefreshCookies);
    yield takeEvery('SUBMIT_MFA', executeMfa);
    yield takeEvery('SUBMIT_RESEND_OTP', executeResendOtp);
}