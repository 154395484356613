import React, { forwardRef } from 'react'

const SVG = forwardRef(
  (
    {
      size,
      height,
      width,
      fill,
      strokeWidth,
      stroke,
      className,
      children,
      viewBox,
      ...props
    },
    ref
  ) => {
    return (
      <svg
        className={className}
        width={width || size || "24"}
        height={height || size || "24"}
        viewBox={viewBox || "0 0 24 24"}
        fill={fill || "none"}
        stroke={stroke || "currentColor"}
        strokeWidth={strokeWidth || "2"}
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        {children}
      </svg>
    );
  }
);

const Path = forwardRef(
  (props, ref) => {
    return (
      <path
        {...props}
        stroke={props.stroke || "inherit"}
        strokeWidth={props.width || "inherit"}
        strokeLinecap={props.strokeLinecap || "round"}
        strokeLinejoin={props.strokeLinejoin || "round"}
        ref={ref}
      />
    );
  }
);

const DefaultLazyImportFallback = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="20" height="20" rx="4" ry="4" fill="gray" />
    </svg>
  );
};

export { SVG, Path, DefaultLazyImportFallback };
