import { useNavigate } from 'react-router-dom';
import './change-password.scss';

const ChangePasswordSuccess = () => {
    const navigate = useNavigate();
    
    const goToHome = () => {
        navigate('/auth/login')
    }

    return (
        <div className="wrapper wrapper-full-page ps section-image">
            <div className="change-pwd-container">

                <h3 style={{textAlign: "left"}} className="title">Cambio password</h3>
                <p>La password è stata cambiata correttamente.</p>
                <div style={{textAlign: "center"}} className="footer">
                    <button
                        className="btn-round mb-3 btn btn-info "
                        onClick={goToHome}>
                        Torna alla Home
                    </button>
                </div>

            </div>
        </div>
    );
}

export default ChangePasswordSuccess;
