import { useNavigate } from "react-router-dom";
import './password-recovery.scss';

const PasswordRecoverySuccess = () => {
    const navigate = useNavigate();

    const goToLogin = () => {
        navigate('/auth/login')
    }

    return (
        <div className="wrapper wrapper-full-page ps section-image">
            <div className="password-recovery-container">

                <h3 style={{ textAlign: "left" }} className="title">Ho dimenticato la password</h3>
                <p>Se il tuo ID è abilitato all’utilizzo di Vitruvius riceverai via mail il link per cambiare la password.</p>
                <div style={{ textAlign: "center" }} className="footer">
                    <button
                        className="btn-round mb-3 btn btn-info "
                        onClick={goToLogin}>
                        Torna al login
                    </button>
                </div>

            </div>
        </div>
    );
}

export default PasswordRecoverySuccess;
