import Loader from '../Loader/Loader';
import { toggleUserInfoPopupAct } from "../../containers/Layout/_actions";
import { LogoutAct } from "../../containers/AuthLayout/_actions";
import { stsLogoutAct } from "../../containers/AuthLayout/_actions";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectMain } from "../../reducers";
import { loadUserInfoAct } from "../../containers/Layout/_actions";
import { useIsCustomUser } from '../../hooks/useIsCustomUser';
import { store as suppModuleStore } from 'vitruvius-support-module'
import isFeatureEnabled from '../../utils/feature-flags.service';


const UserInfo = (props: any) => {
  const dispatch = useDispatch();
    const navigate = useNavigate();
    const main = useSelector(selectMain);
  const isCustomUser = useIsCustomUser();

  props = {
    ...props,
    ...main,
    reloadUserInfo: () => dispatch(loadUserInfoAct()),
  }

  const logoutUser = () => {
    dispatch(toggleUserInfoPopupAct());
    dispatch(LogoutAct());
    dispatch(stsLogoutAct());
    suppModuleStore && suppModuleStore.dispatch(LogoutAct())
    navigate(window.REACT_APP_BASE_PATH + '/auth/login')
  }

  const getAgencyCode = (_agency_code: any) => {
    let agencyCode = '--';
    if (_agency_code && _agency_code.length) {
      if (Array.isArray(_agency_code)) agencyCode = _agency_code[_agency_code.length - 1];
      else agencyCode = _agency_code;
    }
    return agencyCode;
  };

  const onChangePasswordClick = () => {
    dispatch(toggleUserInfoPopupAct());
    navigate(window.REACT_APP_BASE_PATH + '/admin/change-pwd');
  }

  const renderUserProfileListInfo = (passwordExpiration?: string) => {
    return (
      <>
        <button
          aria-hidden="true"
          className="close close-btn"
          data-dismiss="modal"
          type="button"
          onClick={() => dispatch(toggleUserInfoPopupAct())}>
          <i className="nc-icon nc-simple-remove"></i>
        </button>

        <dl className="descriptive-data">
          <dt>Nome: </dt>
          <dd>{props.userInfo.fullname || "--"}</dd>
          {!isCustomUser && <>
            <dt>Codice Agenzia: </dt>
            <dd>{getAgencyCode(props.userInfo.agency_code)}</dd>
          </>}
          <dt>Email: </dt>
          <dd>{props.userInfo.mail || "--"}</dd>
          <dt>ID utente: </dt>
          <dd>{props.userInfo.unipolId || "--"}</dd>
        </dl>

        {isCustomUser && isFeatureEnabled(window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE) &&
          <>
            <span style={{ fontSize: "14px", fontWeight: "bolder", cursor: "pointer", color: "#51bcda", textDecoration: "underline" }} onClick={onChangePasswordClick}>
              Cambio password
            </span>
            {passwordExpiration && <span style={{ fontSize: "14px" }}>Data scadenza password {passwordExpiration}</span>}
          </>
        }

        <li className="button-container close-button">
          <button
            style={{ width: "100%" }}
            className="btn-round mb-3 btn btn-info"
            onClick={logoutUser}>
            Logout
          </button>
        </li>
      </>);
  }

  const renderErrorMsg = () => {
    return (
      <>
        <div className="error-msg-popup-container">
          <p className="text-danger">{props.userInfoLoadingError ? 'Si è verificato un errore nel caricamento delle informazioni dell\'utente. Prova a ricaricarle e se il problema persiste, contatta l\'assistenza.' : ''}</p>
        </div>
        <div className="button-container">
          <button
            style={{ width: "100%" }}
            className="btn-round mb-3 btn btn-info"
            onClick={() => props.reloadUserInfo()}>
            Ricarica
          </button>
        </div>
      </>
    );
  }

  let showPopupClass = props.showPopupUserInfo ? 'show' : '';
  let UserProfileInfo = (props.userInfo) ? renderUserProfileListInfo() : null;
  let ErrorMsg = renderErrorMsg();
  return (
    <div className="fixed-plugin">
      <div className={"dropdown " + showPopupClass}>
        <ul className="dropdown-menu show">
          {props.userInfoIsLoading ? <Loader /> : null}
          {props.userInfoIsLoading || props.userInfoLoadingError ? null : UserProfileInfo}
          {props.userInfoLoadingError ? ErrorMsg : null}
        </ul>
      </div>
    </div>
  );
}

export default UserInfo;
