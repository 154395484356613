import { useEffect, useRef } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { NavigateFunction, Outlet, useNavigate } from "react-router-dom";
import applyAxiosInterceptor from "../../utils/http-interceptor-config";

var ps: any;

const AuthLayout = () => {
  const mainPanel: any = useRef()
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    // apply interceptor
    applyAxiosInterceptor(navigate);

    if ((!navigator.platform || navigator.platform.indexOf("Win") > -1) && mainPanel?.current) {
      ps = new PerfectScrollbar(mainPanel?.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    return () => {
      if ((!navigator.platform || navigator.platform.indexOf("Win") > -1) && ps) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="wrapper">
      <div className="main-panel" id="main-panel">
        <Outlet />
      </div>
    </div>
  );
}

export default AuthLayout;
