import PasswordRecovery from "./components/Password/PasswordRecovery";
import PasswordRecoverySuccess from "./components/Password/PasswordRecoverySuccess";
import ChangePassword from "./components/Password/ChangePassword";
import ChangePasswordSuccess from "./components/Password/ChangePasswordSuccess";
import CommunicationDetailsPage from "./containers/communication-details/CommunicationDetailsPage";
import CommunicationsDashboardPage from "./containers/communications-dashboard/CommunicationsDashboardPage";
import CommunicationsPage from "./containers/communications/CommunicationsPage";
import LoginPage from "./containers/login/LoginPage";
import MultiFactorAuth from "./containers/login/MultiFactorAuth";
import PasswordRecoveryExpired from "./components/Password/PasswordRecoveryExpired";
import ErrorPage from "./pages/ErrorPage";
import { Navigate } from "react-router";
import isFeatureEnabled from "./utils/feature-flags.service";


export const getDashboardRoutes = (_window) => [
  {
    path: "dashboard",
    id: "Dashboard",
    element: <CommunicationsDashboardPage />,
    enabled: true,
  },
  {
    path: "communications/details",
    id: "Communication Detail",
    element: <CommunicationDetailsPage />,
    enabled: true,
  },
  {
    path: "communications",
    id: "Communications",
    element: <CommunicationsPage />,
    enabled: true,
  },
  {
    path: "details",
    id: "Detail",
    element: <CommunicationDetailsPage />,
    enabled: true,
  },
  {
    path: "change-pwd",
    id: "Change Password",
    element: <ChangePassword />,
    enabled: isFeatureEnabled(_window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE),
  },
  {
    path: "*",
    element: <Navigate to={"/error"} replace />
  }
];

export const getAuthRoutes = (_window) => [
  {
    path: "login",
    id: "Login",
    element: <LoginPage />,
    enabled: true,
  },
  {
    path: "mfa",
    id: "MFA",
    element: <MultiFactorAuth />,
    enabled: isFeatureEnabled(_window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE),
  },
  {
    path: "pwd-recovery",
    id: "Password Recovery",
    element: <PasswordRecovery />,
    enabled: isFeatureEnabled(_window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE),
  },
  {
    path: "pwd-recovery-success",
    id: "Password Recovery Success",
    element: <PasswordRecoverySuccess />,
    enabled: isFeatureEnabled(_window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE),
  },
  {
    path: "change-expired-pwd",
    id: "Change Expired Password",
    element: <ChangePassword isExpired/>,
    enabled: isFeatureEnabled(_window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE),
  },
  {
    path: "reset-pwd",
    id: "Reset Password",
    element: <ChangePassword isReset/>,
    enabled: isFeatureEnabled(_window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE),
  },
  {
    path: "pwd-recovery-expired",
    id: "Password Recovery Expired",
    element: <PasswordRecoveryExpired />,
    enabled: isFeatureEnabled(_window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE),
  },
  {
    path: "change-pwd-success",
    id: "Change Password Success",
    element: <ChangePasswordSuccess />,
    enabled: isFeatureEnabled(_window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE),
  },
  {
    path: "error",
    id: "Error page",
    element: <ErrorPage />,
    enabled: true,
  },
  {
    path: "*",
    element: <Navigate to={"/error"} replace />
  }
];
