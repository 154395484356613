import { useEffect, useState } from "react";
import { VtvSupport } from "vitruvius-support-module";
import "vitruvius-support-module/src/scss/core.scss";

import "vitruvius-support-module/src/assets/scss/paper-dashboard.scss";
import "vitruvius-support-module/src/assets/demo/demo.css";
import { useSelector } from "react-redux";
import { selectMain } from "../../reducers";
import { goToWithSelectedComponent, isFinitaliaFunction, LOCAL_STORAGE_KEY, readFromQueryString, SupportQuerystringParamEnum } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

const CommunicationDetailsPage = () => {
  const navigate = useNavigate();
  const main = useSelector(selectMain);
  const [idIn, setIdIn] = useState<string>('')

  const goToHome = () => {
    goToWithSelectedComponent(navigate, window.REACT_APP_BASE_PATH + "/admin/communications", main?.selectedComponentValue)
  };

  const goToDashboard = () => {
    goToWithSelectedComponent(navigate, window.REACT_APP_BASE_PATH + "/admin/dashboard", main?.selectedComponentValue)
  };

  useEffect(() => {
    const _id: any = readFromQueryString(SupportQuerystringParamEnum.ID)
    if (!_id) {
      goToHome()
    } else {
      setIdIn(_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const _isFinitalia = isFinitaliaFunction(main?.selectedComponentValue)

  return (
    <div className="embed-container">
      {idIn && <VtvSupport
        idIn={idIn}
        localStorageKey={LOCAL_STORAGE_KEY}
        selectedComponentValue={main.selectedComponentValue}
        showCommunications={false}
        showDetails={true}
        goBack={goToHome}
        goToDashboard={goToDashboard}
        documentsGridView={true}
        //FINITALIA SETTINGS
        hideTimelineSection={_isFinitalia}
        hideDetailsHeaderSection={_isFinitalia}
      />
      }
    </div>
  );
};

export default CommunicationDetailsPage
